import _types from "../client/types";
import _constants from "./constants";
import _helper from "./helper";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPermitted = exports.getPermissionKey = exports.isPerformanceOrWebVitalsAction = exports.isAppMessage = exports.isAppBridgeAction = void 0;
var types_1 = _types;
var constants_1 = _constants;
var helper_1 = _helper;
/**
 * Predicate to determine if an action is an App Bridge action.
 * @public
 */
function isAppBridgeAction(action) {
  return action instanceof Object && Object.prototype.hasOwnProperty.call(action, "type") && action.type.toString().startsWith(constants_1.PREFIX);
}
exports.isAppBridgeAction = isAppBridgeAction;
/**
 * Predicate to determine if an event originated from an application.
 * @internal
 */
function isAppMessage(event) {
  if (typeof event !== "object" || !event.data || typeof event.data !== "object") {
    return false;
  }
  var data = event.data;
  return Object.prototype.hasOwnProperty.call(data, "type") && helper_1.findMatchInEnum(types_1.MessageType, data.type) !== undefined;
}
exports.isAppMessage = isAppMessage;
/**
 * Function used to determine if an action is in the Performance or WebVitals groups
 * @public
 */
function isPerformanceOrWebVitalsAction(_a) {
  var type = _a.type;
  return type.match(/^APP::(PERFORMANCE|WEB_VITALS)::/);
}
exports.isPerformanceOrWebVitalsAction = isPerformanceOrWebVitalsAction;
/**
 * Returns the action type without the prefix and group
 * @internal
 */
function getPermissionKey(type) {
  return type.replace(new RegExp("^" + constants_1.PREFIX + constants_1.SEPARATOR + "\\w+" + constants_1.SEPARATOR), "");
}
exports.getPermissionKey = getPermissionKey;
/**
 * Predicate to determine if an action is permitted
 * @internal
 */
function isPermitted(features, _a, permissionType) {
  var group = _a.group,
    type = _a.type;
  if (!group || !Object.prototype.hasOwnProperty.call(features, group)) {
    return false;
  }
  var feature = features[group];
  if (!feature) {
    return false;
  }
  var actionType = getPermissionKey(type);
  return feature[actionType] ? feature[actionType][permissionType] === true : false;
}
exports.isPermitted = isPermitted;
export default exports;
export const __esModule = exports.__esModule;
const _isPermitted = exports.isPermitted,
  _getPermissionKey = exports.getPermissionKey,
  _isPerformanceOrWebVitalsAction = exports.isPerformanceOrWebVitalsAction,
  _isAppMessage = exports.isAppMessage,
  _isAppBridgeAction = exports.isAppBridgeAction;
export { _isPermitted as isPermitted, _getPermissionKey as getPermissionKey, _isPerformanceOrWebVitalsAction as isPerformanceOrWebVitalsAction, _isAppMessage as isAppMessage, _isAppBridgeAction as isAppBridgeAction };